import type { NextPageWithLayout } from 'next'
import React from 'react'
import { getPublicLayout } from '@public/components/PublicLayout'
import { Error } from 'components/Error'
import { prepareGetStaticNavigationProps } from './utils/prepareGetStaticNavigationProps'

interface NotFoundProps {
  icon?: React.ReactNode
  description?: React.ReactNode
}

const NotFound: NextPageWithLayout<React.PWC<NotFoundProps>> = ({
  icon,
  description,
}) => (
  // @ts-ignore Add proper types
  <Error icon={icon} description={description} />
)

export function getStaticProps() {
  return prepareGetStaticNavigationProps()
}

NotFound.getLayout = getPublicLayout

NotFound.seoProps = {
  description:
    'Oops, something went wrong. Please try again later or contact us for support',
}

export default NotFound
